import { Input, Label } from "nav-frontend-skjema";
import React, { useState } from "react";
import AlertStripe from "nav-frontend-alertstriper";

import "./main.less";
import { ContentProps as WizardContentProps } from "../../../common/wizard/Wizard";
import { format } from "../../../../formatter";
import { Header } from "../../../common/header/Header";
import { isGeneral } from "../../../../type-guards";
import { setOneTimeCode } from "../../../authSlice";
import { useDispatch, useSelector } from "../../../../hooks";
import { useCount } from "./useCount";

export const Register = ({ content, children }: WizardContentProps) => {
  const code = useSelector((state) => state.auth.oneTimeCode),
    dispatch = useDispatch(),
    [hint, setHint] = useState(false);
  // [works, setWorks] = useState<boolean | undefined>(),
  // [studies, setStudies] = useState<boolean | undefined>(),
  // [mastersLanguage, setMastersLanguage] = useState<boolean | undefined>(),
  // [invitedByNav, setInvitedByNav] = useState<boolean | undefined>(),
  // radios = ["Ja", "Nei"].map((str, i) => ({ label: str, value: !i + "" }));

  const count = useCount();
  const closed = count >= 2004;

  return (
    <>
      <Header title={content?.title?.toUpperCase()} />
      <section className="register">
        {closed ? (
          <div className="info">
            <p className="red">
              <strong>Registrering stengt!</strong>
            </p>
            <p className="red">
              Vi har nådd maksimalt antall deltakere for prosjektet og kan
              desverre ikke registrere flere.
            </p>
          </div>
        ) : (
          <div className="info">
            {isGeneral(content) && format(content?.welcome, false)}
          </div>
        )}
        <Input
          {...{ label: "Deltaker-ID", value: code || "" }}
          disabled={closed}
          onChange={(event) => {
            dispatch(setOneTimeCode(event.target.value));
            setHint(event.target.value === null || event.target.value === "");
          }}
          {...(hint ? { feil: "Deltaker-ID mangler" } : {})}
        />
        <Label htmlFor="">
          Skriv inn koden du fikk ved invitasjon til studien.
        </Label>
        <strong>Før du går videre lurer vi på følgende:</strong>
        {/* <RadioPanelGruppe
          {...{
            name: "work",
            legend: "Er du i 100% jobb i dag?",
            radios: radios.map((radio) => ({ ...radio, disabled: closed })),
          }}
          checked={works === undefined ? works : works ? "true" : "false"}
          onChange={(_, value) => {
            setWorks(value === "true");
            setHint(code === null || code === "");
          }}
        />
        <RadioPanelGruppe
          {...{
            name: "study",
            legend: "Er du fulltidsstudent i dag?",
            radios: radios.map((radio) => ({ ...radio, disabled: closed })),
          }}
          checked={studies === undefined ? studies : studies ? "true" : "false"}
          onChange={(_, value) => setStudies(value === "true")}
        />
        <RadioPanelGruppe
          {...{
            name: "language",
            legend:
              "Mestrer du norsk eller annet nordisk språk på grunnleggende nivå?",
            radios: radios.map((radio) => ({ ...radio, disabled: closed })),
          }}
          checked={
            mastersLanguage === undefined
              ? mastersLanguage
              : mastersLanguage
              ? "true"
              : "false"
          }
          onChange={(_, value) => setMastersLanguage(value === "true")}
        />
        <strong>
          RØST er kun for de som har mottatt invitasjon fra OT om deltakelse
        </strong>
        <RadioPanelGruppe
          {...{
            name: "invited",
            legend: "Jeg bekrefter herved at jeg har mottatt invitasjon fra OT",
            radios: radios.map((radio) => ({ ...radio, disabled: closed })),
          }}
          checked={
            invitedByNav === undefined
              ? invitedByNav
              : invitedByNav
              ? "true"
              : "false"
          }
          onChange={(_, value) => setInvitedByNav(value === "true")}
        />
        */}
        {code !== null && code !== "" && (
          // mastersLanguage === true &&
          // invitedByNav === true &&
          // works === false &&
          // studies === false &&
          <AlertStripe type="suksess">
            Flott! Du er klar for å gå videre til registrering. På neste side
            får du detaljert informasjon om hva deltakelse innebærer før du kan
            gå videre til samtykke og registrering.
          </AlertStripe>
        )}
        {/* {(works || studies) && (
          <AlertStripe type="feil">
            Obs! Hvis du er i jobb eller utdanning kan du dessverre ikke delta i
            denne studien.
            <br />
            <br />
            Uansett, takk for interessen. Du kan lukke nettleseren.
          </AlertStripe>
        )}
        {mastersLanguage === false && (
          <AlertStripe type="feil">
            Obs! Hvis du ikke mestrer norsk eller et annet nordisk språk på
            grunnleggende nivå kan du dessverre ikke delta i denne studien.
            <br />
            <br />
            Uansett, takk for interessen. Du kan lukke nettleseren.
          </AlertStripe>
        )}
        {invitedByNav === false && (
          <AlertStripe type="feil">
            Obs! Hvis du ikke har mottatt invitasjon fra OT kan du dessverre
            ikke delta i denne studien.
            <br />
            <br />
            Uansett, takk for interessen. Du kan lukke nettleseren.
          </AlertStripe>
        )} */}
        {closed
          ? null
          : children(
              code === null || code === ""
              // works === undefined ||
              // studies === undefined ||
              // mastersLanguage === undefined ||
              // invitedByNav === undefined ||
              // works ||
              // studies ||
              // !mastersLanguage ||
              // !invitedByNav
            )}
      </section>
    </>
  );
};
