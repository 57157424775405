import { useEffect, useState } from "react";
import { authService } from "../../../../api";

export const useCount = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    authService.count().then(({ data }) => {
      setCount(data.data ?? 0);
    });
  }, []);

  return count;
};
